import { useModelStore } from "@/stores/modelStore";

export function retCustomerData(receipt){
    const modelStore = useModelStore();
    const customer   = modelStore.findModel('entityCustomers', receipt.entity_customer_id);

    const data = {
        fullName:       retCustomerFullname(customer),
        vatCondition:   modelStore.entityVatConditions.find((i) => i.id === customer.entity.entity_vat_condition_id),
        doc_number:     customer.entity.doc_number,
        document_type:  modelStore.documentTypes.find((i) => i.id === customer.entity.document_type_id),
        email:          customer.entity.email,
        address:        retCustomerAddress(customer),
        phone:          retCustomerPhone(customer),
        startActivity:  null
    };
    console.log(data);
    return data;
}

export function retSupplierData(receipt){
    const modelStore = useModelStore();
    const supplier   = modelStore.findModel('entitySuppliers', receipt.entity_supplier_id);
    const sAddress   = supplier?.address ?? null;
    const sPhone     = supplier?.phone ?? null;
    const data = {
        fullName:       retSupplierFullname(supplier),
        vatCondition:   supplier ? modelStore.entityVatConditions.find((i) => i.id === supplier.entity_vat_condition_id) : '',
        doc_number:      supplier?.doc_number ?? '',
        document_type:  'CUIT',
        email:          supplier?.email ?? '',
        address:        retFormatedAdrress(sAddress),
        phone:          retFormatedPhone(sPhone),
        startActivity:  null,
    };
    return data;
}

export function retEmployeeData(receipt){
    const modelStore = useModelStore();
    const employee   = modelStore.findModel('entityEmployees', receipt.entity_employee_id);
    const data = {
        fullName:       employee.fullName,
        vatCondition:   modelStore.entityVatConditions.find((i) => i.id === employee.entity.entity_vat_condition_id),
        doc_number:     employee.entity.doc_number,
        document_type:  modelStore.documentTypes.find((i) => i.id === employee.entity.document_type_id),
        email:          employee.entity.email,
        address:        retEmployeeAddress(employee),
        phone:          retEmployeePhone(employee),
        startActivity:  null
    };
    return data;
}

/////////////////////
///// CLIENTES /////
///////////////////
export function retEntityCustomer(entity){
    const modelStore = useModelStore();
    const customer   = modelStore.findModel('entityCustomers', entity.entity_customer_id);
    if(!customer) return {};
    customer.fullName = customer.entity.first_name + ' ' + (customer.entity.last_name ?? '');
    return customer;
}

export function retCustomerFullname(customer){
    if(!customer) return '';
    return customer.entity.first_name + ' ' + (customer.entity.last_name ?? '');
}

export function retCustomerAddress(customer){
    if(isNotEmpty(customer.entity.address)) return retFormatedAdrress(customer.entity.address);
    else if(isNotEmpty(customer.entity.addresses)) return retFormatedAdrress(customer.entity.addresses[0]);
    return '';
}

export function retCustomerPhone(customer){
    if(isNotEmpty(customer.entity.phone)) return retFormatedPhone(customer.entity.phone);
    else if(isNotEmpty(customer.entity.phones)) return retFormatedPhone(customer.entity.phones[0]);
    return '';
}

export function retCustomerDoctype(customer){
    if(!customer) return '';
    const modelStore = useModelStore();
    const docType   = modelStore.documentTypes.find((i) => i.id === customer.entity.document_type_id);
    if(docType) return docType.short_name;
    return '';
}

/////////////////////
///// EMPLEADOS ////
///////////////////
export function retEmployee(entity){
    const modelStore = useModelStore();
    const employee   = modelStore.entityEmployees.find((i) => i.id === entity.entity_employee_id);
    if(!employee) return {};
    employee.fullName = employee.entity.first_name + ' ' + (employee.entity.last_name ?? '');
    return employee;
}

export function retEmployeeFullname(employee){
    if(!employee) return '';
    return employee.entity.first_name + ' ' + (employee.entity.last_name ?? '');
}

export function retEmployeeAddress(employee){
    if(isNotEmpty(employee.entity.address)) return retFormatedAdrress(employee.entity.address);
    else if(isNotEmpty(employee.entity.addresses)) return retFormatedAdrress(employee.entity.addresses[0]);
    return '';
}

export function retEmployeePhone(employee){
    if(isNotEmpty(employee.entity.phone)) return retFormatedPhone(employee.entity.phone);
    else if(isNotEmpty(employee.entity.phones)) return retFormatedPhone(employee.entity.phones[0]);
    return '';
}

export function retEmployeeDoctype(employee){
    if(!employee) return '';
    const modelStore = useModelStore();
    const docType   = modelStore.documentTypes.find((i) => i.id === employee.entity.document_type_id);
    if(docType) return docType.short_name;
    return '';
}

/////////////////////
////// CAJEROS /////
///////////////////
export function retCashierFullname(cashier){
    if(!cashier) return '';
    return cashier.entity.first_name + ' ' + (cashier.entity.last_name ?? '');
}

export function retCashierAddress(cashier){
    if(isNotEmpty(cashier.entity.address)) return retFormatedAdrress(cashier.entity.address);
    else if(isNotEmpty(cashier.entity.addresses)) return retFormatedAdrress(cashier.entity.addresses[0]);
    return '';
}

export function retCashierPhone(cashier){
    if(isNotEmpty(cashier.entity.phone)) return retFormatedPhone(cashier.entity.phone);
    else if(isNotEmpty(cashier.entity.phones)) return retFormatedPhone(cashier.entity.phones[0]);
    return '';
}

export function retCashierDoctype(cashier){
    if(!cashier) return '';
    const modelStore = useModelStore();
    const docType   = modelStore.documentTypes.find((i) => i.id === cashier.entity.document_type_id);
    if(docType) return docType.short_name;
    return '';
}

/////////////////////////
////// PROVEEDORES /////
///////////////////////
export function retSupplierFullname(supplier){
    if(!supplier) return '';
    return supplier.real_name ?? supplier.fantasy_name;
}

export function retSupplierAddress(supplier){
    if(isNotEmpty(supplier.address)) return retFormatedAdrress(supplier.address);
    else if(isNotEmpty(supplier.addresses)) return retFormatedAdrress(supplier.addresses[0]);
    return '';
}

export function retSupplierPhone(supplier){
    if(isNotEmpty(supplier.phone)) return retFormatedPhone(supplier.phone);
    else if(isNotEmpty(supplier.phones)) return retFormatedPhone(supplier.phones[0]);
    return '';
}

export function retSupplierDoctype(supplier){
    if(!supplier) return '';
    const modelStore = useModelStore();
    const docType   = modelStore.documentTypes.find((i) => i.id === supplier.document_type_id);
    if(docType) return docType.short_name;
    return '';
}

/////////////////////
///// GENERALES ////
///////////////////
export function retEntityFullname(entity){
    if(!entity) return 'Sin nombre';
    return entity.first_name + ' ' + (entity.last_name ?? '');
}

export function retMainAddress(entity){
    if(isNotEmpty(entity.address)) return retFormatedAdrress(entity.address);
    else if(isNotEmpty(entity.addresses)) return retFormatedAdrress(entity.addresses[0]);
    else return '';
}

export function retMainPhone(entity){
    if(isNotEmpty(entity.phone)) return retFormatedPhone(entity.phone);
    else if(isNotEmpty(entity.phones)) return retFormatedPhone(entity.phones[0]);
    else return '';
}



export function retFormatedAdrress(address){
    return address ? (address.street + ' ' + (address.street_number ?? '') + (address.city ? (' - ' + address.city) : '')) : '';
}

export function retFormatedPhone(phone){
    return phone ? phone.phone_number ?? '' : '';
    // return phone ? '('+ (phone.country_code ?? '') + ') ' + (phone.phone_number ?? '') : '';
}

