import { useModelStore } from "@/stores/modelStore";
import { usePosStore } from "@/stores/posStore";

// LOS IMPORTES TOTALES INCLUYEN PRECIO DE VENTA, CANTIDADES, DESCUENTOS, IVA E IMPUESTOS
export function retInvoiceTotal(receipt) {
  return receipt.invoice_items.reduce((acc, curr) => (acc += retItemTotal(curr, receipt.purchase_type_id ?? false)) ,0);
}
export function retItemTotal(item, isExpense, excludeIva = false){
  const modelStore = useModelStore();
  const posStore = usePosStore();

  if(isExpense){
    if(excludeIva) item.vat_id = 3;
    const taxesAmount = retTotalItemTaxes(item);
    const vat = modelStore.vats.find((i) => i.id === item.vat_id);
    const costWithIva = posStore.selectedCompany?.entity_vat_condition_id === 1 ? 
      (Num(item.cost_net) + (Num(item.cost_net) * Num(vat.amount)) / 100) + Num(item.cost_untaxed) :
      Num(item.cost_net) + Num(item.cost_untaxed);
    
    return (costWithIva + taxesAmount - Num(item.discount)) * Num(item.quantity);
  } else {
    return (Num(item.price) - Num(item.discount)) * Num(item.quantity);
  }
}

// LOS IMPORTES SUBTOTALES INCLUYEN PRECIO DE VENTA Y CANTIDADES, SIN CONTEMPLAR DESCUENTOS, IVA E IMPUESTOS
export function retInvoiceSubtotal(receipt) {
  return receipt.invoice_items.reduce((arg, itm) => (arg += retItemSubtotal(itm, receipt.purchase_type_id ?? false)) ,0);
}
// DEVUELVE EL SUBTOTAL DE UN ITEM
export function retItemSubtotal(item, isExpense, excludeIva = false){
  const modelStore = useModelStore();
  const posStore = usePosStore();

  if(isExpense){
    if(excludeIva) item.vat_id = 3;
    const vat = modelStore.vats.find((i) => i.id === item.vat_id);
    const costWithIva = posStore.selectedCompany?.entity_vat_condition_id === 1 ? 
      (Num(item.cost_net) + (Num(item.cost_net) * Num(vat.amount) / 100) + Num(item.cost_untaxed)) :
      Num(item.cost_net) + Num(item.cost_untaxed);
    return costWithIva * Num(item.quantity);
  } else {
    if(Num(item.price) < 0) return 0;
    return Num(item.price) * Num(item.quantity);
  }
}

// LOS IMPORTES NETOS INCLUYEN (PRECIO DE VENTA - COSTO SIN IMPUESTOS - DESCUENTOS - IMPUESTOS) * CANTIDADES - IVA
export function retInvoiceTotalNet(item){
  return item.invoice_items.reduce((arg, itm) => (arg += retItemTotalNet(itm)), 0);
}
// DEVUELVE EL TOTAL NETO DE UN ITEM
export function retItemTotalNet(item){
  const totalNet = Num(item.quantity) * (Num(item.price) - Num(item.discount) - Num(item.cost_untaxed) - retTotalItemTaxes(item));
  return totalNet / (1 + Num(item.vat.value));
}

// DEVUELVE EL IMPORTE SIN IMPUESTOS DEL COMPROBANTE
export function retInvoiceTotalUntaxed(item){
  return item.invoice_items.reduce((arg, itm) => (arg += retItemTotalUntaxed(itm)), 0);
}
// DEVUELVE EL IMPORTE SIN IMPUESTOS DE UN ITEM
export function retItemTotalUntaxed(item){
  return Num(item.quantity) * Num(item.cost_untaxed);
}

// DEVUELVE UN OBJETO DE OBJETOS DE IVAs DEL COMPROBANTE
export function retInvoiceVats(item){
  return item.invoice_items.reduce((arg, itm) => {
    arg[itm.vat.id] ??= {
      id: itm.vat.id,
      amount: 0,
      name: itm.vat.name,
      code: itm.vat.code,
      value: itm.vat.value,
      total: 0
    };
    arg[itm.vat.id].amount += retItemVatAmount(itm);
    return arg;
  }, {})
}
// DEVUELVE EL IMPORTE TOTAL DE IVA DEL COMPROBANTE
export function retInvoiceTotalVats(item){
  const allVats = retInvoiceVats(item);
  if(isEmpty(allVats)) return 0;
  const arr = Object.values(allVats);
  return arr.reduce((acc, curr) => acc += curr.amount, 0)
}
// DEVUELVE EL IMPORTE TOTAL DE IVA DE UN ITEM
export function retItemVatAmount(item){
  let vatAmount = 0;
  const vat = useModelStore().vats.find((i) => i.id === item.vat_id);
  if(vat) vatAmount = retItemTotalNet(item) * Num(vat.value);
  return vatAmount;
}
// DEVUELVE EL IMPORTE TOTAL DEL COSTO DE IVA DE UN ITEM
export function retVatAmountCost(item){
  let vatAmount = 0;
  const vat = useModelStore().vats.find((i) => i.id === item.vat_id);
  if(vat) vatAmount = Num(item.cost_net) * Num(vat.amount) / 100;
  return vatAmount;
}

// DEVUELVE UN OBJETO DE OBJETOS DE OTROS IMPUESTOS DEL COMPROBANTE
export function retInvoiceTaxes(item){
  const allTaxes = item.invoice_items.flatMap(invoiceItem => {
    return invoiceItem.taxes.map(tax => ({
      ...tax,
      amount: Num(tax.amount) * Num(invoiceItem.quantity), // Multiplicamos el amount por la cantidad del invoiceItem
    }));
  });

  if(isEmpty(allTaxes)) return {};
  const modelStore = useModelStore();

  return allTaxes.reduce((arg, itm) => {
    const findedTax = modelStore.taxes.find((i) => i.id === itm.tax_id);
    arg[itm.tax_id] ??= {
      id: itm.tax_id,
      aliquot: itm.aliquot,
      amount: 0,
      name: findedTax.name,
      code: findedTax.code,
      tax_base: itm.tax_base
    };
    arg[itm.tax_id].amount += Num(itm.amount);
    return arg;
  }, {})
}
// DEVUELVE EL IMPORTE TOTAL DE OTROS IMPUESTOS DEL COMPROBANTE
export function retTotalInvoiceTaxes(item){
  const allTaxes = retInvoiceTaxes(item);
  if(isEmpty(allTaxes)) return 0;
  const arr = Object.values(allTaxes);
  return arr.reduce((acc, curr) => acc += curr.amount, 0)
}
// DEVUELVE EL IMPORTE TOTAL DE OTROS IMPUESTOS DE UN ITEM
export function retTotalItemTaxes(item){
  return item.taxes.reduce((acc, curr) => acc += Num(curr.amount), 0)
}

// DEVUELVE EL IMPORTE TOTAL DE LOS DESCUENTOS DEL COMPROBANTE
export function retInvoiceDiscount(item){
  const isCreditNote = isNc(item);
  return item.invoice_items.reduce((acc, curr) => {
    const price = Num(curr.price);
    const quantity = Num(curr.quantity);

    if(price < 0) {
      acc += (isCreditNote ? -price * quantity : price * quantity);
    } else {
      const itemDiscount = retItemDiscount(curr);
      acc += (isCreditNote ? itemDiscount : -itemDiscount);
    }
    return acc
  }, 0);
}
// DEVUELVE EL IMPORTE SIN IMPUESTOS DE UN ITEM
export function retItemDiscount(item){
  return Num(item.quantity) * Num(item.discount);
}

// DEVUELVE EL CAMBIO
export function retInvoiceChange(item){
  const total = retInvoiceTotal(item);
  const payment = item.invoice_payments.reduce((acc, curr) => acc += Num(curr.amount), 0);
  return payment - total;
}

export function retCostTotal(receipt){
  return receipt.invoice_items.reduce((acc, curr) => acc += retItemCostTotal(curr), 0);
}
export function retItemCostTotal(item){
  if(item.bar_code !== "desc") {
    const taxes = retTotalItemTaxes(item);
    return (Num(item.cost_net) + Num(item.cost_untaxed) + taxes) * Num(item.quantity);
  }
  return 0;
}

export function retCostNet(receipt){
  return receipt.invoice_items.reduce((arg, itm) => (arg += retItemCostNet(itm)), 0);
}
export function retItemCostNet(item){
  return Num(item.cost_net) * Num(item.quantity);
}

export function retCostUntaxed(receipt){
  return receipt.invoice_items.reduce((arg, itm) => (arg += retItemCostUntaxed(itm)), 0);
}
export function retItemCostUntaxed(item){
  return Num(item.cost_untaxed) * Num(item.quantity);
}

// DEVUELVE LA CANTIDAD TOTAL DE ITEMS DE UN COMPROBANTE
export function retTotalItems(receipt){
  return receipt.invoice_items?.reduce((a, b) => {
    if (b.bar_code !== "desc") return a + Num(b.quantity);
    return 0;
  }, 0);
}

export function retRechargePaymentAmount(receipt){
  return receipt.invoice_payments.reduce((acc, curr) => (acc += Num(curr.recharge)), 0);
}

export function retItemPaymentAmount(payment){
  return Num(payment.amount) + Num(payment.recharge);
}