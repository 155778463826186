import { useModelStore } from "@/stores/modelStore";
import { useGeneralStore } from "@/stores/generalStore";
import { useProfileStore } from "@/pages/system/profile/stores/profileStore";

export async function getEntityEmployees() {
  const modelStore = useModelStore();
  if (modelStore.entityEmployees.length) return;
  const res = await request({
    url: `/entityEmployee.getAll`,
    method: "post",
  });
  modelStore.entityEmployees = res;
}

export async function getSellers() {
  const modelStore = useModelStore();
  if (modelStore.entityEmployees.length) return modelStore.entityEmployees;

  const res = await request({
    url: `/getEntitiesSellers`,
    method: "post",
  });
  modelStore.sellers = res;
  return res;
}

export async function getSellersBtwDates(date = useGeneralStore().date) {
  const modelStore = useModelStore();
  const res = await request({
    url: `/getAllSellersDate`,
    method: "post",
    data: { date }
  });

  modelStore.sellers = res.map((s) => {
    s.totalInvoices = s.invoices.length;
    s.totalSales = s.invoices.reduce((acc, curr) => {
      if(isTrait(curr)) acc += retInvoiceTotal(curr);
      return acc;
    }, 0);

    s.totalDiscounts = s.invoices.reduce((acc, curr) => acc += retInvoiceDiscount(curr), 0);
    s.devolutions = s.invoices.reduce((acc, curr) => {
      if(isNc(curr)) acc += retInvoiceTotal(curr);
      return acc;
    }, 0);
    s.totalNetSales = s.totalSales - s.totalDiscounts - s.devolutions;

    const commission = modelStore.commissions.find((i) => i.id === s.commission_id);
    s.commission = commission ? s.totalNetSales * Num(commission.amount) / 100 : 0;

    return s;
  });
  return res;
}

export async function getSellerInvoices(item, date = useGeneralStore().date) {
  if (item.entity_employee_id) item.id = item.entity_employee_id;
  const res = await request({
    url: `/getSellerPendingAndInvoiced`,
    method: "post",
    data: {entity_employee: item, date}
  });
  
  useModelStore().invoicesList = res;
  return res;
}

export async function getAllSellerReceipts(item, date = useGeneralStore().date) {
  if (item.entity_employee_id) item.id = item.entity_employee_id;
  const res = await request({
    url: `/getAllSellerReceipts`,
    method: "post",
    data: {entity_employee: item, date}
  });
  useProfileStore().receipts = res;
  return res;
}
