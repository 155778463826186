import { retProductFullName } from "@/composables/product/ProductComposable";
import { retInvoiceTotal } from "@/composables/_GlobalFinancialComposable";
import { 
  retReceiptCustomerFullname, retReceiptCustomerAddress, retReceiptCustomerPhone, 
  retReceiptEmployeeFullname, retReceiptEmployeeAddress, retReceiptEmployeePhone, 
  retReceiptCashierFullname, retReceiptCashierAddress, retReceiptCashierPhone, 
  retReceiptSupplierFullname, retReceiptCliOrSupFullname
} from "@/composables/receipts/GeneralReceipts";
import { 
  retCustomerFullname, retCustomerAddress, retCustomerPhone, retCustomerDoctype, 
  retEmployeeFullname, retEmployeeAddress, retEmployeePhone, retEmployeeDoctype, 
  retCashierFullname, retCashierAddress, retCashierPhone, retCashierDoctype, 
} from "@/composables/entities/EntityComposable";

export const functionMap = { retInvoiceTotal, 
  retReceiptCustomerFullname, retReceiptCustomerAddress, retReceiptCustomerPhone, 
  retReceiptEmployeeFullname, retReceiptEmployeeAddress, retReceiptEmployeePhone, 
  retReceiptCashierFullname, retReceiptCashierAddress, retReceiptCashierPhone, 
  retCustomerFullname, retCustomerAddress, retCustomerPhone, retCustomerDoctype, 
  retEmployeeFullname, retEmployeeAddress, retEmployeePhone, retEmployeeDoctype, 
  retCashierFullname, retCashierAddress, retCashierPhone, retCashierDoctype, 
  retReceiptSupplierFullname, retReceiptCliOrSupFullname, 
  retProductFullName,
};