export default {
  defaultTheme: "dark",
  themes: {
    dark: {
      colors: {
        primary: "#6E668E",
        surface: "#191923",
        error: "#9D2817",
        background: "#13131A",
        success: "#0E730D",

        "placeholder-color": "#fff",
        "label-color": "#fff",
        "grey-darken-5": "#161616",
        "grey-darken-6": "#101010",

        "appbar-background": "#13131A",
        "toolbar-bg": "#20202E",
        "toolbar-bg-dark": "#131321",

        // Text
        "on-surface-variant": "#242B33",
        "on-navigation-drawer": "#adbac7",
        "on-appbar-background": "#adbac7",
        "on-ad-card": "#adbac7",
        "on-ad-card-selected": "#a68efd",
        "on-surface": "#fff",
        "on-background": "#fff",

        //Buttons
        "primary-button": "#6e79bb",
        "secondary-button": "#373e47",
        "accent-button": "#272939",
        "button-disabled": "#35331a",
        "accent-button-icon": "#823fff",

        "field-label": "#9D2817",

        // Fields
        "field-outline-focus": "#6e79bb",
        "text-field-background": "#141621",
        "select-background": "#2e363e",

        "field-textarea-outline-focus": "#9D2817",

        // Table
        "table-text-color": "#fff",

        // Cards
        "title-bg": "#6c56c4",
      },

      variables: {
        "medium-emphasis-opacity": 0.8,
        "main-logo": "url(/images/logo-c.png)",

        //Custom vars
        "app-background": "#1f2130",
        "app-typography": "#fff",

        // Fields
        "field-border-opacity": 0.1,
        "field-label": "#cbd4dc",
        "field-outline": "#393a4c",

        // Textarea
        "field-textarea": "#141621",
        "field-textarea-focus": "#9D2817",

        // Table
        "table-text-color": "#000",
      },
    },

    light: {
      colors: {
        primary: "#907AEC",
        surface: "#F2F7F7",
        error: "#D56C5D",
        background: "#E5F0F0",
        success: "#8CD88B",

        "placeholder-color": "#000",
        "label-color": "#000",
        "grey-darken-5": "#161616",
        "grey-darken-6": "#101010",

        "appbar-background": "#E5F0F0",
        "toolbar-bg": "#E5F0F0",
        "toolbar-bg-dark": "#D0DCDC",

        // Text
        "on-surface-variant": "#DEE5EC", // ESTO ES EL COLOR DEL FONDO??? XQ SI ES ON, NO DEBERIA SER FUENTE?
        "on-navigation-drawer": "#546E89",
        "on-appbar-background": "#546E89",
        "on-ad-card": "#546E89",
        "on-ad-card-selected": "#583CBE",
        "on-surface": "#000",
        "on-background": "#000",

        //Buttons
        "primary-button": "#6e79bb",
        "secondary-button": "#373e47",
        "accent-button": "#272939",
        "button-disabled": "#35331a",
        "accent-button-icon": "#823fff",

        // Fields
        "field-outline-focus": "#6e79bb",
        "text-field-background": "#FFF",
        "select-background": "#AFC1D3",

        // Table
        "table-text-color": "#000",

        // Cards
        "title-bg": "#8267f0",
      },
      variables: {
        "medium-emphasis-opacity": 0.9,
        "main-logo": "url(/images/logo-d.png)",

        //Custom vars
        "app-background": "#1f2130",
        "app-typography": "#000",

        // Fields
        "field-border-opacity": 0.1,
        "field-label": "#cbd4dc",
        "field-outline": "#393a4c",

         // Textarea
         "field-textarea": "#E1EAF3",
      },
    },
  },
};
