import { useModelStore } from "@/stores/modelStore";
import { usePosStore } from "@/stores/posStore";

function isExpense(receipt) {
    return !!receipt.purchase_type_id;
}

export function retBranchData(receipt){
    const modelStore = useModelStore();
    const posStore   = usePosStore();
    
    const sAddress   = posStore.selectedBranch.address;
    const sPhone     = posStore.selectedBranch.phone;

    const data = {
        fullName: receipt.invoice_type_id < 1000 ? posStore.selectedCompany.real_name : posStore.selectedCompany.fantasy_name,
        vatCondition:   modelStore.entityVatConditions.find((i) => i.id === posStore.selectedCompany.entity_vat_condition_id),
        doc_number:     posStore.selectedCompany.doc_number,
        document_type:  'CUIT',
        email:          posStore.selectedCompany.email,
        address:        retFormatedAdrress(sAddress),
        phone:          retFormatedPhone(sPhone),
        startActivity:  posStore.selectedCompany.start_activity,
    };
    return data;
}

export function retPointOfSale(receipt){
    const posStore   = usePosStore();
    return posStore.selectedBranch.point_of_sales.find((i) => i.id === receipt.point_of_sale_id);
}

export function formatedInvoiceNumber(receipt){
    return isExpense(receipt) ? null : '000' + receipt.point_of_sale.ptoVta + '-' + (receipt.invoice_type_id < 1000 ? receipt.number_fe : receipt.number);
}

export function retTotalPending(receipt){
    return retInvoiceTotal(receipt) - retTotalPaid(receipt);
}

export function retTotalPaid(receipt){
    return receipt.invoice_payments.reduce((acc, curr) => {
        if(curr.payment_method_id !== 4) acc += (Num(curr.amount) + Num(curr.recharge));
        return acc;
    }, 0);
}


/////////////////////
///// CLIENTES /////
///////////////////
export function retReceiptCustomer(receipt){
    const modelStore = useModelStore();
    const customer   = modelStore.findModel('entityCustomers', receipt.entity_customer_id);
    if(!customer) return {};
    customer.fullName = customer.entity.first_name + ' ' + (customer.entity.last_name ?? '');
    return customer;
}

export function retReceiptCustomerFullname(receipt){
    const modelStore = useModelStore();
    const customer   = modelStore.findModel('entityCustomers', receipt.entity_customer_id);
    if(!customer) return '';
    return customer.entity.first_name + ' ' + (customer.entity.last_name ?? '');
}

export function retReceiptCustomerAddress(receipt){
    const modelStore = useModelStore();
    const customer   = modelStore.entityCustomers.find((i) => i.id === receipt.entity_customer_id);
    if(isNotEmpty(customer.entity.address)) return (customer.entity.address.street ?? '') + ' ' + (customer.entity.address.street_number ?? '');
    else if(isNotEmpty(customer.entity.addresses)) return (customer.entity.addresses[0].street ?? '') + ' ' + (customer.entity.addresses[0].street_number ?? '');
    else return '';
}

export function retReceiptCustomerPhone(receipt){
    const modelStore = useModelStore();
    const customer   = modelStore.entityEmployees.find((i) => i.id === receipt.entity_customer_id);
    if(isNotEmpty(customer.entity.phone)) return (customer.entity.phone.country_code ?? '') + customer.entity.phone.phone_number;
    else if(isNotEmpty(customer.entity.phones)) return (customer.entity.phones[0].country_code ?? '') + customer.entity.phones[0].phone_number;
    else return '';
}

///////////////////////
///// PROVEEDORES ////
/////////////////////
export function retReceiptSupplierFullname(receipt){
    const modelStore = useModelStore();
    const supplier   = modelStore.findModel('entitySuppliers', receipt.entity_supplier_id);
    if(!supplier) return '';
    return supplier.real_name ?? supplier.fantasy_name ?? 'Proveedor';
}

/////////////////////
///// EMPLEADOS ////
///////////////////
export async function retReceiptEmployee(receipt){
    const modelStore = useModelStore();
    await getEntityEmployees();
    const employee   = modelStore.entityEmployees.find((i) => i.id === receipt.entity_employee_id);
    if(!employee) return {};
    employee.fullName = employee.entity.first_name + ' ' + (employee.entity.last_name ?? '');
    return employee;
}

export function retReceiptEmployeeFullname(receipt){
    const modelStore = useModelStore();
    const employee   = modelStore.entityEmployees.find((i) => i.id === receipt.entity_employee_id);
    if(!employee) return '';
    return employee.entity.first_name + ' ' + (employee.entity.last_name ?? '');
}

export function retReceiptEmployeeAddress(receipt){
    const modelStore = useModelStore();
    const employe   = modelStore.entityEmployees.find((i) => i.id === receipt.entity_employee_id);
    if(isNotEmpty(employe.entity.address)) return (employe.entity.address.street ?? '') + ' ' + (employe.entity.address.street_number ?? '');
    else if(isNotEmpty(employe.entity.addresses)) return (employe.entity.addresses[0].street ?? '') + ' ' + (employe.entity.addresses[0].street_number ?? '');
    else return '';
}

export function retReceiptEmployeePhone(receipt){
    const modelStore = useModelStore();
    const employe   = modelStore.entityEmployees.find((i) => i.id === receipt.entity_employee_id);
    if(isNotEmpty(employe.entity.phone)) return (employe.entity.phone.country_code ?? '') + (employe.entity.phone.phone_number ?? '');
    else if(isNotEmpty(employe.entity.phones)) return (employe.entity.phones[0].country_code ?? '') + (employe.entity.phones[0].phone_number ?? '');
    else return '';
}

/////////////////////
////// CAJEROS /////
///////////////////
export async function retReceiptCashier(receipt){
    const modelStore = useModelStore();
    await getEntityEmployees();
    const cashier   = modelStore.entityEmployees.find((i) => i.entity.user.id === receipt.user_id);
    if(!cashier) return {};
    cashier.fullName = cashier.entity.first_name + ' ' + (cashier.entity.last_name ?? '');
    return cashier;
}

export function retReceiptCashierFullname(receipt){
    const modelStore = useModelStore();
    const cashier   = modelStore.entityEmployees.find((i) => i.entity.user.id === receipt.user_id);
    if(!cashier) return '';
    return cashier.entity.first_name + ' ' + (cashier.entity.last_name ?? '');
}

export function retReceiptCashierAddress(receipt){
    const modelStore = useModelStore();
    const cashier   = modelStore.entityEmployees.find((i) => i.entity.user.id === receipt.user_id);
    if(isNotEmpty(cashier.entity.address)) return cashier.entity.address.street + ' ' + cashier.entity.address.street_number;
    else if(isNotEmpty(cashier.entity.addresses)) return cashier.entity.addresses[0].street + ' ' + cashier.entity.addresses[0].street_number;
    else return '';
}

export function retReceiptCashierPhone(receipt){
    const modelStore = useModelStore();
    const cashier   = modelStore.entityEmployees.find((i) => i.entity.user.id === receipt.user_id);
    if(isNotEmpty(cashier.entity.phone)) return cashier.entity.phone.country_code + cashier.entity.phone.phone_number;
    else if(isNotEmpty(cashier.entity.phones)) return cashier.entity.phones[0].country_code + cashier.entity.phones[0].phone_number;
    else return '';
}

///////////////////////////////
///// CLIENTE O PROVEEDOR ////
/////////////////////////////
export function retReceiptCliOrSupFullname(receipt){
    const res = receipt.operationable ? receipt.operationable : receipt;
    if(!!res.purchase_type_id) return retReceiptSupplierFullname(res);
    return retReceiptCustomerFullname(res);
}
