import { defineStore, getActivePinia } from "pinia";
import { useGlobalStore } from "@/stores/globalStore";
import { cachedViews } from "@/config/keepAlive";
import { usePosStore } from "./posStore";
import { useModelStore } from "./modelStore";

// Función para hidratar el store desde IndexedDB
const hydrateSessionStore = () => new Promise(async (resolve) => {
  const sessionStore = useSessionStore();

  try {
    const db = await new Promise((resolve, reject) => {
      const request = window.indexedDB.open('piniaPersistedState', 1);

      request.onsuccess = (event) => resolve(event.target.result);
      request.onerror = (event) => reject(event);
    });

    const transaction = db.transaction(['piniaPersistedState'], 'readonly');
    const objectStore = transaction.objectStore('piniaPersistedState');

    const request = objectStore.get('session');
    request.onsuccess = (event) => {
      // Verificamos que obtengamos un evento y posea target
      if(event){
        if(event.target){
          if(event.target.result){
            const data = JSON.parse(event.target.result);
            if (data && data.token) {
              // Recuperamos el token de la base de datos
              sessionStore.token = data.token;
              // Reemplazamos los headers con el token recuperado
              axios.defaults.headers.common.Authorization = `Bearer ${sessionStore.token}`;
            }
          }
        }
      }
      resolve(); // Resuelve la promesa después de completar la operación
    };
  } catch (error) {
    console.error('Error hydrating store from IndexedDB:', error);
    resolve(); // También resuelve en caso de error para no bloquear la ejecución
  }
});

export const useSessionStore = defineStore("session", {
  state: () => ({
    user: null,
    account: null,
    token: null,
    failLogin: false,
    loggedIn: false,
    blockedAccount: false,
    notVerified: false,
    valid: true,
    userLogin: {
      email: "",
      password: "",
      remember: false,
    },
    persistModels: null,
    isProcessing: false,
  }),

  persist: true,
  // persist: { paths: ["token"] },

  getters: {
    isRoot(state) {
      return state.user?.role_id === 1;
    },

    isAdmin(state) {
      return (
        state.user?.role?.name === "Admin" || state.user?.role?.name === "Root"
      );
    },

    isLoggedIn() {
      return !!this.token;
    },

    accountPlane() {
      return this.account.account_plan;
    },

    userCompanies() {
      return this.account.companies ?? [];
    },

    userBranches() {
      let branches = [];
      this.userCompanies.forEach((c) => branches.push(...c.branches));
      return branches;
    },

    userPoses() {
      let poses = [];
      this.userBranches.forEach((b) => poses.push(...b.point_of_sales));
      return poses;
    },

    userWarehouses() {
      return this.user.entity?.entity_employee.warehouses;
    },

    canChangePos(){
      return this.userCompanies.length > 1 || this.userBranches.length > 1 || this.userPoses.length > 1;
    },

    adminOptions() {
      const posStore = usePosStore();
      if(this.isRoot){
        return {
          canCreateBranch: posStore.selectedCompany.branches_qty < posStore.selectedBranch.point_of_sales?.length,
          canCreatePos: posStore.selectedCompany.point_of_sales_qty < posStore.selectedBranch.point_of_sales?.length,
        }
      }
      return [];
    },

  },

  actions: {
    async hydrate() {
      await hydrateSessionStore();
    },

    async login(userLogin) {
      useGlobalStore().buttonLoader = true;
      useGlobalStore().activeLoader();

      try {
        const res = await axios.post("/login", userLogin);

        if (res.status === 200) {
          const { token, user, account } = res.data.result;

          this.token = token;
          this.user = user;
          this.account = account;
          this.loggedIn = true;
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
          this.persistModels = { excludes: ['productFamilies'] };

          await this.router.push("Desktop");
        } else {
          this.failLogin = true;
          showAlertMsg('Hubo un error de validación');
        }
      } catch (e) {
        this.failLogin = true;
        if (e.response?.data?.error === "blocked") this.blockedAccount = true;
        if (e.response?.data?.error === "notVerified") this.notVerified = true;
        showError(e);
      } finally {
        setTimeout(() => {
          this.failLogin = false;
        }, 500);
        useGlobalStore().buttonLoader = false;
      }
    },

    async getUserData() {
      if(!useGlobalStore().isOnline) return;

      axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      axios.defaults.withCredentials = true;
      try {
        const res = await axios.get(`${import.meta.env.VITE_BaseApiURL}/getUserData`);
        if(!useGlobalStore().isOnline) return;

        if (res.status === 200) {
          this.user = res.data.result['user'];
          this.account = res.data.result['account'];
        } else {
          showAlertMsg('Hubo un error de validación');
        }
      } catch (e) {
        await showError(e);
      }
    },

    async logOut() {
      try {
        useGlobalStore().activeLoader();
        useGlobalStore().buildLoader = false;
        await logout();
        await this.clearStore();
      } catch (e) {
        showError(e);
      }
    },

    async clearStore() {
      try {
        axios.defaults.headers.common.Authorization = "";
        await this.destroyPiniaAndLS();
        this.router.replace("/login");
      } catch (e) {
        showError(e);
      }
    },

    async resetStates() {
      const excludedStores = ['app', 'appBuilder', 'global', 'pos', 'session'];
      const modelStore = useModelStore();
      modelStore.products = [];

      setTimeout(() => {
        const pinia = getActivePinia();
        pinia._s.forEach(async (store, index) => {
          if(!excludedStores.includes(index)) {
            if(store.$reset) await store.$reset();
            // if(store.$persistActive) store.$clearIndexedDBStore(store);
            if(index.includes('module')){
              delete pinia._s[index];
            }
          };
        });
      }, 250);
    },

    async getStoresSize() {
      const pinia = getActivePinia();
      let totalSize = 0;
      pinia._s.forEach((store, index) => {
        const storeData = JSON.stringify(store.$state);
        const sizeInBytes = new Blob([storeData]).size; // Tamaño en bytes
        const sizeInKB = sizeInBytes / 1024;
        totalSize += sizeInKB / 1024;
        console.log(`El store ${index} pesa aproximadamente ${sizeInKB.toFixed(2)} MB`);
      });
      console.log(`El tamaño total de los stores es de ${totalSize} KB`);

      const totalMemoryUsed = performance.memory.usedJSHeapSize / 1024 / 1024;
      const totalMemoriSize = performance.memory.totalJSHeapSize / 1024 / 1024;
      console.log(`El uso total de memoria total es de ${totalMemoryUsed}MB de ${totalMemoriSize}MB`)
    },

    async destroyPiniaAndLS() {
      const pinia = getActivePinia();
      pinia._s.forEach((store, index) => {
        if(store.$reset) store.$reset();
        // if(store.$persistActive) store.$clearIndexedDBStore(store);
        // store.$clearIndexedDB();
        if(index.includes('module')){
          delete pinia._s[index];
        }
      });
      await this.testClearPersist();
      useGlobalStore().desactiveLoader();
    },

    async testClearPersist(){
      try {
        const request = window.indexedDB.open('piniaPersistedState', 1);
    
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          db.createObjectStore('piniaPersistedState');
        };
    
        request.onsuccess = async (event) => {
          const db = event.target.result;
          const transaction = db.transaction(['piniaPersistedState'], 'readwrite');
          const objectStore = transaction.objectStore('piniaPersistedState');
          await objectStore.clear();
        };
    
        request.onerror = (event) => {
          console.error('Error clearing IndexedDB:', event);
          throw event;
        };
      } catch (error) {
        console.error('Error clearing IndexedDB:', error);
        throw error;
      }
    }
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useSessionStore, import.meta.hot));
