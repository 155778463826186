// COMPROBANTES DE VENTAS
// COMPROBANTES DE AFIP
export const feInvoiceTypes = [1, 6, 11, 51, 201, 206, 211];
export const feNd = [2, 7, 12, 52, 202, 207, 212];
export const feNc = [3, 8, 13, 53, 203, 208, 213];
export const feRe = [4, 9, 15, 54];
export const feAndNcNd = feInvoiceTypes.concat(feNd, feNc);
export const allFeInvoiceTypes = feInvoiceTypes.concat(feNd, feNc, feRe);

// COMPROBANTES PERSONALIZADOS
export const noFeInvoiceTypes = [1001];
export const noFeNd = [1002];
export const noFeNc = [1003];
export const noFeRe = [1004];
export const noFeAndNcNd = noFeInvoiceTypes.concat(noFeNd, noFeNc);
export const allNoFeInvoiceTypes = noFeInvoiceTypes.concat(noFeNd, noFeNc, noFeRe);

export const feAndNoFeND = feNd.concat(noFeNd);
export const feAndNoFeNC = feNc.concat(noFeNc);
export const feAndNoFeRE = feRe.concat(noFeRe);

export const feAndNoFe = feInvoiceTypes.concat(noFeInvoiceTypes); //COMPROBANTES SOLO DE VENTAS
export const feAndNoFeNcNd = feAndNcNd.concat(noFeAndNcNd);  //COMPROBANTES VENTAS + NC + ND
export const allInvoiceTypes = allFeInvoiceTypes.concat(allNoFeInvoiceTypes);  //COMPROBANTES VENTAS + NC + ND + RE

export const QUOTE = [2000];
export const ORDERS = [2001, 2003, 2005];
export const INTERNALORDER = [3001];

export const TRAITFEANDNOFE = feAndNoFe.concat(ORDERS);


// COMPROBANTES DE COMPRAS
export const BUYORDERS = [2001, 2003, 2005];
export const BUYFEANDNOFE = feAndNoFe.concat(BUYORDERS);

export const isTrait        = (receipt) => TRAITFEANDNOFE.includes(receipt.invoice_type_id);
export const isFeReceipt    = (receipt) => allFeInvoiceTypes.includes(receipt.invoice_type_id);
export const isNc           = (receipt) => feAndNoFeNC.includes(receipt.invoice_type_id);

export const startTime = [
    { hours: 0, minutes: 0, seconds: 0 },
    { hours: 23, minutes: 59, seconds: 59 },
];