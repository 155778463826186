import { useModelStore } from "@/stores/modelStore";
import { usePosStore } from "@/stores/posStore";

export function updatePivotPrice(item, priceList) {
  const posStore = usePosStore();
  let vatAmount = 0;
  if (item.vat_id) {
    const vat = useModelStore().vats.find((i) => i.id === item.vat_id);
    if(vat && posStore.selectedCompany.config.hasVatDiscriminated) vatAmount = +vat.amount;
  }

  const taxes = retProdTotalTaxes(item);
  let costWithIva = +item.cost_net + (+item.cost_net * vatAmount) / 100;
  let totalPrice = +costWithIva + +item.cost_untaxed;
  let mkupResult = totalPrice + taxes + (totalPrice * +priceList.percent) / 100;

  return withDecimals(mkupResult, 2);
}

export function retProductPrice(item) {
  const modelStore = useModelStore();
  const posStore = usePosStore();

  if(item.bar_code === 'desc') return 0;
  let mkup = { amount: 0 };
  if (item.product_mkup_id) {
    mkup = modelStore.productMkups.find((i) => i.id === item.product_mkup_id);
  }

  let vat = { amount: 0 };
  if (item.vat_id && posStore.selectedCompany.config.hasVatDiscriminated) {
    vat = modelStore.vats.find((i) => i.id === item.vat_id);
  }

  const costWithIva = +item.cost_net + (+item.cost_net * vatAmount) / 100;
  const taxes = retProdTotalTaxes(item);
  const totalCost = +costWithIva + +item.cost_untaxed;
  const mkupResult = totalCost + taxes + (totalCost * +mkup.amount) / 100;

  return withDecimals(mkupResult, 2);
}

export function updateCost(item) {
  const modelStore = useModelStore();
  const posStore = usePosStore();

  if(item.bar_code === 'desc') return 0;
  let mkup = { amount: 0 };
  if (item.product_mkup_id) {
    mkup = modelStore.productMkups.find((i) => i.id === item.product_mkup_id);
  }
  let vat = { amount: 0 };
  if (item.vat_id && posStore.selectedCompany.config.hasVatDiscriminated) {
    vat = modelStore.vats.find((i) => i.id === item.vat_id);
  }

  const costWithIva = +item.cost_net + (+item.cost_net * +vat.amount) / 100;
  const totalCost = +costWithIva + +item.cost_untaxed;
  const taxes = retProdTotalTaxes(item);

  item.total_cost = withDecimals(totalCost + taxes, 2);
}

export function updateCostAndPrice(item) {
  const modelStore = useModelStore();
  const posStore = usePosStore();

  if(item.bar_code === 'desc') return 0;
  let mkup = { amount: 0 };
  if (item.product_mkup_id) {
    mkup = modelStore.productMkups.find((i) => i.id === item.product_mkup_id);
  }
  let vat = { amount: 0 };
  if (item.vat_id && posStore.selectedCompany.config.hasVatDiscriminated) {
    vat = modelStore.vats.find((i) => i.id === item.vat_id);
  }

  const costWithIva = +item.cost_net + (+item.cost_net * +vat.amount) / 100;
  const totalCost = +costWithIva + +item.cost_untaxed;
  const taxes = retProdTotalTaxes(item);
  const mkupResult = totalCost + taxes + ((totalCost * +mkup.amount) / 100);

  item.price_base = withDecimals(mkupResult, 2);
  item.total_cost = withDecimals(totalCost + taxes, 2);
}

export function retProdTotalTaxes(item){
  if(!item.taxes) return 0;
  return item.taxes.reduce((acc, curr) => acc += Number(curr.pivot.amount), 0)
}

export function retProductFullName(product){
  const modelStore = useModelStore();
  const unit = modelStore.measurementUnits.find((i) => i.id === (product.measurement_unit_id ?? product.product?.measurement_unit_id));
  const brand = modelStore.productBrands.find((i) => i.id === (product.product_brand_id ?? product.product?.product_brand_id));
  return (brand?.name ?? '') + ' - ' + product.name + ' ' + sinCeros(product.amount_content) + (unit?.short_name ?? '');
}

export function retTotalInventory(item) {
  let sum = item.warehouses.reduce(
    (arg, itm) => (arg += +itm.pivot.inventory),
    0
  );
  return sum;
}

export function retTotalStock(item) {
  let sum = item.warehouses.reduce(
    (arg, itm) => (arg += +itm.pivot.stock),
    0
  );
  return sum;
}
