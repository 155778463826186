import { useSessionStore } from "@/stores/sessionStore";
import { useModelStore } from "@/stores/modelStore";

export const useProfileStore = defineStore("profile", {
    state: () => ({
        receipts: [],
    }),

    getters: {
        invoices() {
          return this.receipts.invoices ?? [];
        },
        pendingSOs() {
            return this.receipts.pendingSOs ?? [];
        },
        saleOrders() {
            return this.receipts.saleOrders ?? [];
        },

        totalInvoicesBruteAmount(){
            return !this.invoices.length ? 0 : this.invoices.reduce((acc, itm) => +acc + +itm.totalSales, 0);
        },
        totalInvoicesDiscounts(){
            return !this.invoices.length ? 0 : this.invoices.reduce((acc, itm) => +acc + +itm.totalDiscounts, 0)
        },
        totalInvoicesNetAmount(){
            return !this.invoices.length ? 0 : this.invoices.reduce((acc, itm) => +acc + +itm.totalNetSales, 0)
        },

        totalPendingSOsBruteAmount(){
            return !this.pendingSOs.length ? 0 : this.pendingSOs.reduce((acc, itm) => +acc + +itm.totalSales, 0)
        },
        totalPendingSOsDiscounts(){
            return !this.pendingSOs.length ? 0 : this.pendingSOs.reduce((acc, itm) => +acc + +itm.totalDiscounts, 0)
        },
        totalPendingSOsNetAmount(){
            return !this.pendingSOs.length ? 0 : this.pendingSOs.reduce((acc, itm) => +acc + +itm.totalNetSales, 0)
        },

        totalSaleOrdersBruteAmount(){
            return !this.saleOrders.length ? 0 : this.saleOrders.reduce((acc, itm) => +acc + +itm.totalSales, 0)
        },
        totalSaleOrdersDiscounts(){
            return !this.saleOrders.length ? 0 : this.saleOrders.reduce((acc, itm) => +acc + +itm.totalDiscounts, 0)
        },
        totalSaleOrdersNetAmount(){
            return !this.saleOrders.length ? 0 : this.saleOrders.reduce((acc, itm) => +acc + +itm.totalNetSales, 0)
        },

        totalPending() {
            return this.pendingSOs.reduce((a, b) => +a + +b.totalNetSales, 0);
        },
        totalInvoiced() {
            return this.invoices.reduce((a, b) => +a + +b.totalNetSales, 0);
        },

        //PRODUCTS
        totalInvoicesProducts(){
            let res = 0;
            this.invoices.forEach((i) => res += this.buildTotalProducts(i));
            return res;
        },

        profileCustomers(){
            return useModelStore().entityCustomers.filter((i) => i.entity_employee_id === useSessionStore().user.entity.entity_employee.id)
        },

        getCustomersWithSales(){
            return this.invoices.reduce((acc, curr) => {
                const customer = acc.find((i) => i.id === curr.entity_customer_id);
                if(!customer) {
                    const res = useModelStore().entityCustomers.find((i) => i.id === curr.entity_customer_id);
                    acc.push({...res});
                }
                return acc;
            }, [])
        }
    },

    actions: {
        buildTotalProducts(item){
            return item.invoice_items.reduce((ac, it) => {
                if (it.bar_code !== "desc" && it.bar_code && it.sku !== "888") {
                    if (!it.bar_code.includes("Promo")) {
                    return +ac + +it.quantity;
                    }
                    return +ac;
                }
                return +ac;
            }, 0)
        },


    }
});
  
if (import.meta.hot)
import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot));
