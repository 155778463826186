import { useModelStore } from "@/stores/modelStore";
import { usePosStore } from "@/stores/posStore";

export const dialogGeneralTable = ref(false);
export const generalTableTitle = ref("");
export const generalTableSelectedItems = ref([]);
export const generalTableItems = ref([]);
export const generalTableHeaders = ref([]);
export const generalTableDateFilter = ref(false);
export const generalTableHasActions = ref(false);

export function checkDel(item) {
  deleteItem.value = item;
  dialogDeleteStatus.value = true;
}

export function getObjectValues(items, column, object) {
  let newItem = items[column.value].find((i) => i.id === object.id);
  if (newItem) {
    return newItem.pivot[column.columnRowText];
  }
  return 0;
}

export function showPayments(item, column) {
  let newItem = {
    title: moneyFormat(Number(item.amount) + Number(item.recharge)),
    subtitle: item.payment_method.short_name,
  };
  return newItem;
}

export function checkRowStyle(item, moduleUsed) {
  const posStore = usePosStore();
  if (moduleUsed === "Product") {
    let color = '';
    let stock = 1;
    if (posStore.hasStockAlert) {
      if(item.stock <= 5) {
        stock = 2
        color = "bg-yellow-darken-1";
      };
      if(item.stock <= 0) {
        stock = 3
        color = "bg-yellow-darken-4";
      };
    }
    if (posStore.hasExpirationAlert) {
      if(item.expiration_date){
        const now = getNowDate();
        const diff = dateDiffInDays(item.expiration_date, now);
        if(diff <= item.exp_first_alert){
          if(stock === 1) color = "bg-pink";
          if(stock === 2) color = "bg-pink-darken-2";
          if(stock === 3) color = "bg-pink-darken-4";
        };
        if(diff <= item.exp_last_alert) {
          if(stock === 1) color = "bg-red";
          if(stock === 2) color = "bg-red-darken-2";
          if(stock === 3) color = "bg-red-darken-4";
        };
      }
    }
    return color;
  }

  if(moduleUsed === "CustomerDashboard"){
    if(item.total_invoices < 1)
    return "bg-red-darken-4";
  }

  if (moduleUsed === "SaleReport") {
    if (isFeReceipt(item) && !item.cae) {
      return "bg-yellow-darken-2";
    }
  }
}

export function getAlertText(item, moduleUsed){
  const posStore = usePosStore();
  if (moduleUsed === "Product") {
    let msg = null;
    let stock = 1;
    if (posStore.hasStockAlert) {
      if(item.stock <= 5) {
        stock = 2
        msg = "El stock es muy bajo";
      };
      if(item.stock <= 0) {
        stock = 3
        msg = "El stock es bajo";
      };
    }
    if (posStore.hasExpirationAlert) {
      if(item.expiration_date){
        const now = getNowDate();
        const diff = dateDiffInDays(item.expiration_date, now);
        if(diff <= item.exp_first_alert){
          if(stock === 1) msg = "La fecha de vencimiento esta muy próxima";
          if(stock === 2) msg = "La fecha de vencimiento esta muy próxima y el stock es muy bajo";
          if(stock === 3) msg = "La fecha de vencimiento esta muy próxima y el stock es bajo";
        };
        if(diff <= item.exp_last_alert) {
          if(stock === 1) msg = "La fecha de vencimiento esta próxima";
          if(stock === 2) msg = "La fecha de vencimiento esta próxima y el stock es muy bajo";
          if(stock === 3) msg = "La fecha de vencimiento esta próxima y el stock es bajo";
        };
      }
    }
    return msg;
  }
  return null;
}

export function retRelationValue(value, column){
  const modelStore = useModelStore();
  let relation = column.value.replace('_id', '');
  relation = relation.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
  relation = pluralize(relation);
  if(!modelStore[relation]) return "Valor no encontrado";

  const res = modelStore[relation].find(i => i.id === value);

  if(res) return column.columnRowText ? res[column.columnRowText] : 'Nombre sin definir';
  return 'Sin valor';
}

export function retRelationToFilter(item, column){
  if (column.columnRowText in functionMap) return functionMap[column.columnRowText](item);
  return 'Sin valor';
}

export function getComposedValue(value, column){
  const modelStore = useModelStore();
  const columnValues = column.columnRowText.split(',').map(v => v.trim());

  const processedValues = columnValues.map(col => {
    if (col.includes('_id')) {
      let relation = col.replace('_id', '');
      relation = relation.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
      relation = pluralize(relation);

      const res = modelStore[relation].find(i => i.id === value[col]);
      return res ? res.name : ''; // Devuelve el nombre o un string vacío si no se encuentra
    }
    return value[col] ? isNumber(value[col]) ? sinCeros(value[col]) : value[col] : col; // Si no tiene '_id', se devuelve el valor original
  });

  return processedValues.join(' ');
}